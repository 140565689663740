import useSWR from "swr";
import { get } from "lodash";

import InstagramIcon from "components/_others/icons/InstagramIcon";
import FacebookIcon from "components/_others/icons/FacebookIcon";
import YouTubeIcon from "components/_others/icons/YouTubeIcon";

import styles from "./Footer.module.scss";

const Footer = () => {
  const { data } = useSWR(
    "/api/pages/footer",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const webinstance = get(data, "webinstance", {});

  const year = new Date().getFullYear();

  return (
    <>
      <footer id={styles.footer}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={`${styles.col} ${styles["col-4"]}`}>
              <a
                href="https://www.pcrevue.sk/Archiv-NXT"
                className={styles?.logo}
              >
                <img
                  src="https://www.nextech.sk/library/nextech/logo-footer.png"
                  alt="logo"
                />
              </a>
            </div>
            <div className={`${styles.col} ${styles["col-6"]}`}>
              <div className={styles?.row}>
                <div className={`${styles.col} ${styles["col-4"]}`}>
                  <h3>Nextech</h3>
                  <div className={styles.row}>
                    <div className={`${styles.col} ${styles["no-padding"]}`}>
                      <ul>
                        <li>
                          <a href="https://www.nextech.sk/HomeOffice">
                            HomeOffice
                          </a>
                        </li>
                        <li>
                          <a href="https://www.nextech.sk/elektromobilita">
                            Elektromobilita
                          </a>
                        </li>
                        <li>
                          <a href="https://www.nextech.sk/umela-inteligencia">
                            Umelá inteligencia
                          </a>
                        </li>
                        <li>
                          <a href="https://www.nextech.sk/itgala">IT GALA</a>
                        </li>
                        <li>
                          <a href="https://www.nextech.sk/PDF">PDF</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={`${styles.col} ${styles["col-4"]}`}>
                  <h3>Kategórie</h3>
                  <ul>
                    <li>
                      <a href="https://www.nextech.sk/elektromobilita/technologie">
                        Technológie{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/Prieskumy-1">
                        Prieskumy{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/Pripadove-studie">
                        Pripadové štúdie{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/elektromobilita/rocenka">
                        Ročenka{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/elektromobilita/katalog">
                        Katalóg{" "}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className={`${styles.col} ${styles["col-4"]}`}>
                  <h3>Služby</h3>
                  <ul>
                    <li>
                      <a href="https://www.nextech.sk/itgala">IT GALA</a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/it-rocenka">IT Ročenka</a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/podujatia">Podujatia</a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/community/articles/communityCategory/Sutaze">
                        Súťaže
                      </a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/community/articles/communityCategory/Blogy">
                        Blogy
                      </a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/NXTplus">NXTplus</a>
                    </li>
                    <li>
                      <a href="https://forum.pcrevue.sk/">PCforum</a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/community/articles/communityCategory/praca-v-it">
                        Práca v IT
                      </a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/rss">RSS</a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/inzercia">Inzercia</a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/site/newsletter">
                        Newsletter
                      </a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/a/aktualne-predplatne">
                        Predplatné
                      </a>
                    </li>
                    <li>
                      <a href="https://www.nextech.sk/kontakt">Kontakt</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`${styles.col} ${styles["col-2"]}`}>
              <div className={styles?.socials}>
                <a
                  href="https://www.instagram.com/nextech.magazin/"
                  target="_blank"
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://www.facebook.com/pg/pcrevue/posts/"
                  target="_blank"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.youtube.com/channel/UC51n0kPREVzryruUQLUDyvQ/videos"
                  target="_blank"
                >
                  <YouTubeIcon />
                </a>
              </div>
            </div>
          </div>
          {/* <div className={styles.row}>
            <div className={styles.col + " " + styles.copyright}>
              {year} © {get(webinstance, "globalSettings.name_of_company")} |
              prevádzkované eshop systémom{" "}
              <a href="https://www.grandus.sk" rel="nofollow sponsored">
                Grandus
              </a>{" "}
              od spoločnosti{" "}
              <a href="https://www.forbestclients.com" rel="nofollow sponsored">
                For Best Clients, s.r.o.
              </a>
            </div>
          </div> */}
        </div>
      </footer>
      <div className={styles?.copyright} role="contentinfo">
        <div className={styles.container}>
          <div className={styles?.row}>
            <div className={`${styles.col} ${styles["col-6"]}`}>
              <a href="http://www.forbestclients.com">
                <img
                  src="/img/logo-foot-forbestclients.png"
                  width="200px"
                  alt="Logo For Best Clients"
                />
              </a>
              <p>
                <a
                  href="http://www.forbestclients.com"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Vytvorené spoločnosťou For Best Clients, s.r.o.
                </a>
              </p>
            </div>
            <div className={`${styles.col} ${styles["col-6"]}`}>
              <a href="http://www.websupport.sk">
                <img
                  src="/img/logo-foot-websupport.png"
                  width="200px"
                  alt="Logo WebSupport"
                />
              </a>
              <p>
                <a
                  href="http://www.websupport.sk"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Hostingove služby poskytuje spoločnosť WebSupport, s.r.o.
                </a>
              </p>
            </div>
          </div>

          <hr style={{ opacity: ".1" }} />
          <p>© 2020 všetky práva vyhradené • Nextech: 2644-6804</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
