// import UserButton from "components/user/AuthButton";
import useSWR from "swr";
import get from "lodash/get";
import useCompare from "grandus-lib/hooks/useCompare";
import { useState, useRef } from "react";

import Link from "next/link";
import {
  PlusOutlined,
  MenuOutlined,
  DiffOutlined,
  DiffFilled,
} from "@ant-design/icons";
import { Badge, Tooltip } from "antd";
import Image from "next/image";

import Menu from "components/menu/Menu";
import Transition from "pages/_transition";
import Search from "components/header/Search";
import InfoBox from "components/infoBox/InfoBox";

const Header = () => {
  const inputRef = useRef();
  const { data } = useSWR(
    "/api/pages/header",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const webinstance = get(data, "webinstance", {});
  const infobox = get(data, "infobox", []);
  const { compare, isLoading: isLoadingCompare } = useCompare();

  const [openedSearch, setOpenedSearch] = useState(false);
  const onClickToggleOpenSearch = () => {
    if (openedSearch) {
      setOpenedSearch(false);
    } else {
      setOpenedSearch(true);
      inputRef.current.focus();
    }
  };

  const [openedMenu, setOpenedMenu] = useState(false);
  const onClickToggleOpenMenu = () => {
    if (openedMenu) {
      setOpenedMenu(false);
    } else {
      setOpenedMenu(true);
    }
  };
  const updateOpenedMenu = (opened) => {
    if (openedMenu !== opened) {
      setOpenedMenu(opened);
    }
  };

  const menuData = {};
  menuData.options = {};
  // menuData.options.megamenu = { type: "auto" };
  menuData.options.logo = get(webinstance, "logo");
  if (menuData.options.logo) {
    menuData.options.logo.resolution = "/110x60";
  }
  menuData.updateOpenedMenu = updateOpenedMenu;
  menuData.isOpen = openedMenu;

  const iconWrapperStyle = {
    width: "25px",
    height: "25px",
    display: "inline-flex",
  };

  return (
    <>
      {/* <InfoBox data={infobox} /> */}
      <header id={"header"}>
        <div className={"container"}>
          <div className={"logo"}>
            <Link href="/" as={`/`}>
              <a>
                <Image src="/img/logo.svg" width={200} height={25} alt="logo" />
                <span>KATALÓG</span>
              </a>
            </Link>
          </div>
          <div className="right">
            <div className={"menu"}>
              <ul>
                <li className={"show-search"}>
                  <a href="#" onClick={onClickToggleOpenSearch}>
                    <div className={"icon"}>
                      {!openedSearch ? (
                        <svg
                          height={32}
                          width={32}
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <title>search</title>
                          <g>
                            <path d="M27.166 27.461c.388-.177.59-.446.666-.817v-.033l.008-.034v-.202a.775.775 0 0 1-.042-.143c-.025-.076-.042-.135-.06-.177a1.109 1.109 0 0 0-.285-.337l-2.518-2.206c-.842-.741-1.684-1.466-2.501-2.198-.085-.076-.21-.177-.236-.37-.009-.186.11-.304.194-.397a9.785 9.785 0 0 0 2.332-3.84 9.205 9.205 0 0 0 .388-3.79c-.203-1.944-.96-3.696-2.24-5.203-1.44-1.685-3.293-2.83-5.508-3.41a9.86 9.86 0 0 0-2.088-.312L13.735 4c-1.634.135-3.15.59-4.514 1.381-2.139 1.221-3.655 2.947-4.53 5.12a8.849 8.849 0 0 0-.607 2.779v.025c-.008.657-.008 1.162.034 1.348v.008c.236 1.903.834 3.495 1.836 4.842 1.355 1.81 3.174 3.082 5.431 3.79 1.255.387 2.594.547 3.941.454 1.6-.11 3.116-.539 4.514-1.296.236-.127.463-.11.665.075.876.767 1.769 1.55 2.62 2.308.858.758 1.76 1.532 2.643 2.299.278.244.489.362.733.404.025 0 .042.008.067.008h.245c.025-.008.05-.008.084-.017a.964.964 0 0 0 .27-.067zm-10.922-5.945c-.59.11-1.179.168-1.751.168-2.013 0-3.832-.673-5.44-2.03A7.368 7.368 0 0 1 6.5 15.428c-.396-1.903-.11-3.713.901-5.372 1.12-1.878 2.804-3.116 4.985-3.722a8.438 8.438 0 0 1 3.369-.21c1.793.243 3.36.984 4.657 2.205a7.68 7.68 0 0 1 2.282 4c.11.472.16.952.16 1.449-.009 2.114-.767 3.95-2.257 5.431a8.093 8.093 0 0 1-4.354 2.308z" />
                          </g>
                        </svg>
                      ) : (
                        <PlusOutlined className={"iconAnd close-icon"} />
                      )}
                    </div>
                  </a>
                </li>
                <li className={"show-compare"}>
                  <Link href="/porovnanie" as={`/porovnanie`}>
                    <a>
                      <Tooltip placement="bottomRight" title="porovnanie">
                        <div className={"icon"}>
                          <Badge count={get(compare, "productIds", []).length}>
                            <div style={iconWrapperStyle}>
                              {get(compare, "productIds", []).length ? (
                                <DiffFilled className={"iconAnd"} />
                              ) : (
                                <DiffOutlined className={"iconAnd"} />
                              )}
                            </div>
                          </Badge>
                        </div>
                      </Tooltip>
                    </a>
                  </Link>
                </li>
                <li className={"show-menu"}>
                  <a href="#" onClick={onClickToggleOpenMenu}>
                    <div className={"icon"}>
                      {!openedMenu ? (
                        <MenuOutlined className={"iconAnd"} />
                      ) : (
                        <PlusOutlined className={"iconAnd close-icon"} />
                      )}
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className={`search desktop`}>
              <div className={"search-wrapper"}>
                <Search />
              </div>
            </div>
          </div>
        </div>
        <div className={`search mobile ${openedSearch ? "open" : ""}`}>
          <div className={"search-wrapper"}>
            <Search inputRef={inputRef} />
          </div>
        </div>
      </header>

      <div className={"desktop-menu"}>
        <div className={"container"}>
          <Transition>
            <Menu {...menuData} />
          </Transition>
        </div>
      </div>
    </>
  );
};

export default Header;
