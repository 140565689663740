// import 'scripts/wdyr';
import "../styles/custom-antd.css";
import "../styles/custom-grid.scss";
import "../styles/header.scss";
import "../styles/global.scss";

import Head from "next/head";

import { useMemo } from "react";

import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";

import Router from "next/router";
import TagManager from "grandus-lib/utils/gtag";
import FBPixel from "grandus-lib/utils/fbpixel";

import Header from "components/header/Header";
import Footer from "components/footer/Footer";

import dynamic from "next/dynamic";
const CookieConsent = dynamic(
  () => import("components/_others/cookieConsent"),
  { ssr: false }
);

import NProgress from "nprogress";
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// export function reportWebVitals(metric) {
//   if (metric.label === "custom") {
//     console.info(`⏱️ | ${metric.name} - ${metric.value}ms`);
//   }
// }

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const GrandusApp = ({ Component, pageProps }) => {
  React.useEffect(() => {
    TagManager.registerPageViewTracking(Router);
    FBPixel.registerPageViewTracking(Router);
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Head>
      <div className="headerSection">
        {useMemo(
          () => (
            <Header />
          ),
          []
        )}
      </div>
      <Component {...pageProps} />
      {useMemo(
        () => (
          <Footer />
        ),
        []
      )}
      <CookieConsent />
    </>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default GrandusApp;
