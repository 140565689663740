import useSWR from "swr";
import { get, indexOf, remove, uniq } from "lodash";
import Link from "next/link";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";
import { getImageUrl } from "grandus-lib/utils";
import { useEffect, useState } from "react";

import styles from "components/menu/Menu.module.scss";

import dynamic from "next/dynamic";
import { HomeOutlined, PlusOutlined } from "@ant-design/icons";
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);

const generateKey = (level = 0, id, key, suffix = "") =>
  `category-menu-level-${level}-${id}-${key}${suffix}`;

const LinkMobileAll = ({ name, urlName, onClickMethod }) => {
  return (
    <Link {...getCategoryLinkAttributes(urlName)} scroll={true}>
      <a className={styles.mobile} onClick={() => onClickMethod(false)}>
        {`Všetko z kategórie ${name}`}
      </a>
    </Link>
  );
};

const LinkMobile = ({ item, isOpen, onClickMethod, onClickMethod2 }) => {
  const hasSubmenu = get(item, "children", []).length > 0;
  const hasSubmenuCssClass = hasSubmenu ? styles["has-submenu"] : "";
  return (
    <Link
      {...getCategoryLinkAttributes(
        item?.urlName,
        "",
        {},
        { absoluteHref: get(item, "externalUrl") }
      )}
      scroll={true}
    >
      <a
        className={`${hasSubmenuCssClass} ${styles.mobile} ${
          isOpen ? styles.open : ""
        }`}
        onClick={(e) => {
          if (hasSubmenu) {
            e.preventDefault();
            onClickMethod(item?.id);
          } else {
            onClickMethod2(false);
          }
        }}
      >
        {item?.name}
      </a>
    </Link>
  );
};

const Menu = ({ isOpen = false, updateOpenedMenu, options = {} }) => {
  const { data, error } = useSWR(
    "/api/lib/v1/categories?depth=0",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const [openedCategories, setOpenedCategory] = useState([]);

  // make page body fixed (do not scoll)
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (isOpen) {
      body.classList.add("no-scroll");
    } else {
      body.classList.remove("no-scroll");
    }

    return () => {
      body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  const onClickToggleOpenCategory = (categoryId) => {
    if (indexOf(openedCategories, categoryId) >= 0) {
      setOpenedCategory(remove(openedCategories, (n) => n != categoryId));
    } else {
      setOpenedCategory(uniq([...openedCategories, categoryId]));
    }
  };
  return (
    <div className={styles["megamenu"]}>
      <nav className={isOpen ? styles.open : ""}>
        {!data ? (
          <ul className={styles.main}>
            <li>&nbsp;</li>
          </ul>
        ) : (
          <>
            <a
              className={`${styles?.close} ${styles?.mobile} close-icon`}
              href="#"
              onClick={() => {
                updateOpenedMenu(false);
                return false;
              }}
            >
              <PlusOutlined />
            </a>
            <ul className={styles.main}>
              {data.map((item, index) => {
                const submenuItemsCount = get(item, "children", []).length;
                const isOpen = indexOf(openedCategories, get(item, "id")) >= 0;

                return (
                  <li key={generateKey(0, item?.id, index)}>
                    <Link
                      {...getCategoryLinkAttributes(
                        item?.urlName,
                        "",
                        {},
                        { absoluteHref: get(item, "externalUrl") }
                      )}
                      scroll={true}
                    >
                      <a
                        className={
                          submenuItemsCount ? styles["has-submenu"] : ""
                        }
                      >
                        {get(item, "hash") ===
                        "CATEGORY_BACK_TO_NEXTECH_HASH" ? (
                          <span>
                            <HomeOutlined /> &nbsp;
                          </span>
                        ) : (
                          get(item, "name")
                        )}
                      </a>
                    </Link>
                    <LinkMobile
                      item={item}
                      onClickMethod={onClickToggleOpenCategory}
                      onClickMethod2={updateOpenedMenu}
                      isOpen={isOpen}
                    />

                    {submenuItemsCount ? (
                      <div
                        className={`${styles["megamenu-item"]} ${
                          isOpen ? styles.open : ""
                        }`}
                      >
                        <div
                          className={`${styles["column"]} ${
                            get(options, "megamenu.type") == "auto"
                              ? styles["menu-col-auto"]
                              : ""
                          } ${styles.mobile}`}
                        >
                          <LinkMobileAll
                            name={get(item, "name")}
                            urlName={get(item, "urlName")}
                            onClickMethod={updateOpenedMenu}
                          />
                        </div>

                        {get(item, "children", []).map((subItem, index) => {
                          const hasSubSubmenu = get(
                            subItem,
                            "children",
                            []
                          ).length;
                          let divStyle = {};
                          if (get(subItem, "alternativePhoto.path")) {
                            divStyle = {
                              backgroundImage: `url("${getImageUrl(
                                subItem?.alternativePhoto,
                                "150x150",
                                "jpg"
                              )}")`,
                            };
                          }
                          return (
                            <div
                              style={divStyle}
                              key={generateKey(1, subItem?.id, index)}
                              className={`${styles["column"]} ${
                                get(options, "megamenu.type") == "auto"
                                  ? styles["menu-col-auto"]
                                  : styles[
                                      `menu-col-${
                                        submenuItemsCount > 5
                                          ? 5
                                          : submenuItemsCount
                                      }`
                                    ]
                              }`}
                            >
                              <Link
                                {...getCategoryLinkAttributes(
                                  get(subItem, "urlName")
                                )}
                                scroll={true}
                              >
                                <a
                                  className={`${styles["megamenu-title"]} ${
                                    hasSubSubmenu ? styles["has-submenu"] : ""
                                  }`}
                                >
                                  {/* <Image
                                  photo={get(subItem, "alternativePhoto", {})}
                                  size={"20x28"}
                                  type={"png"}
                                /> */}
                                  {get(subItem, "name")}
                                </a>
                              </Link>
                              <LinkMobile
                                item={subItem}
                                onClickMethod={onClickToggleOpenCategory}
                                onClickMethod2={updateOpenedMenu}
                              />
                              {hasSubSubmenu ? (
                                <div
                                  className={`${styles["megamenu-wrap"]} ${
                                    indexOf(
                                      openedCategories,
                                      get(subItem, "id")
                                    ) >= 0
                                      ? styles.open
                                      : ""
                                  }`}
                                >
                                  <ul>
                                    <li>
                                      <LinkMobileAll
                                        name={get(subItem, "name")}
                                        urlName={get(subItem, "urlName")}
                                        onClickMethod={updateOpenedMenu}
                                      />
                                    </li>
                                    {subItem.children.map(
                                      (subSubItem, index) => {
                                        if (
                                          index == 6 &&
                                          hasSubSubmenu !== index
                                        ) {
                                          return (
                                            <li
                                              key={generateKey(
                                                2,
                                                subSubItem?.id,
                                                index,
                                                "-show-more"
                                              )}
                                            >
                                              <Link
                                                {...getCategoryLinkAttributes(
                                                  get(subItem, "urlName")
                                                )}
                                                scroll={true}
                                              >
                                                <a>
                                                  <u>Zobraziť ďaľšie</u>
                                                </a>
                                              </Link>
                                            </li>
                                          );
                                        }
                                        if (index > 6) {
                                          return "";
                                        }
                                        return (
                                          <li
                                            key={generateKey(
                                              2,
                                              subSubItem?.id,
                                              index
                                            )}
                                          >
                                            <Link
                                              {...getCategoryLinkAttributes(
                                                get(subSubItem, "urlName")
                                              )}
                                              scroll={true}
                                            >
                                              <a
                                                className={styles.always}
                                                onClick={() => {
                                                  updateOpenedMenu(false);
                                                }}
                                              >
                                                {get(subSubItem, "name")}
                                              </a>
                                            </Link>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}

              <li className={styles.separator}></li>

              <li className={styles.mobile}>
                <Link href="/porovnanie" as={`/porovnanie`}>
                  <a
                    onClick={() => {
                      updateOpenedMenu(false);
                    }}
                  >
                    Porovnanie
                  </a>
                </Link>
              </li>
            </ul>
          </>
        )}
      </nav>
    </div>
  );
};

export default Menu;
